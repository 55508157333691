import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

export class Footer extends Component {
  render() {
    return (
      <footer className="Footercolor text-white ">
        <div className="container pb-3">
          <div className="row justify-content-between">
            {/* Left Container */}
            <div className="col-lg-3">
              <h3>Om Oss</h3>
              <p>Vi är dedikerade till att förse dig med kvalitetsbilar som är noggrant utvalda och kontrollerade. Hos oss kan du sälja din bil enkelt och snabbt. Vi ser till att alla bilar vi köper är i utmärkt skick och fungerar precis som de ska. Hos oss får du alltid högsta prisgaranti. När du säljer eller förmedlar din bil genom oss kan du vara säker på att du får det bästa priset på marknaden.</p>
            </div>


              {/* Right Container */}
              <div className="col-lg-3">
              <h3>Företagsinformation</h3>
              <p className='mb-1'>AutoXchange AB</p>
              <p className='mb-1'>Cypressvägen 10A</p>
              <p className='mb-1'>213 63 Malmö</p>
              <p className='mb-1'>info@autoxchange.se</p>
              {/* <p className='mb-1' style={{"width":"fitcontent"}}><a href='tel:0762656575' style={{ "color": "white", "textDecoration": "unset" }}>076 265 6575</a></p> */}
              <p className='mb-1' style={{"width":"fitcontent"}}> <a href='tel:0708182583' style={{ "color": "white", "textDecoration": "unset" }}>070 818 2583</a></p>
              
              {/* <p>OrgNr: XXXXXX-XXXX</p> */}
            </div>

            {/* Center Container */}
            <div className="col-lg-3">
              <h3>Butikens Öppettider</h3>
              <p className='mb-1'>Mån-fre: 09:00 - 18:00</p>
              <p>Lördag - Söndag: Vi öppnar när kunder bokar tid med oss</p>
            </div>

          

            <div className="col-lg-2">
              <h3>Tjänster</h3>
              <a className='tjansterstyle' href='/contact'>Kontakt</a><br/>
              <a className='tjansterstyle' href='/faq'>FAQ</a><br/>
              <a className='tjansterstyle' href='/omoss'>Om oss</a><br/><br/>
              <a className='tjansterstyle' href='https://www.instagram.com/auto_x_change'  target='_blank'  rel='noopener noreferrer'><FontAwesomeIcon style={{ "marginRight": "3px", "color": "#fb37d7", "fontSize":"20px"}} icon={faInstagram} />Instagram</a>
              
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
