import React, {useState, useLayoutEffect, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import './SellCar.css';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faPhone } from '@fortawesome/free-solid-svg-icons'

function Sellcar() {

    // Send email vardering
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [mileage, setMileage] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const { pathname } = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);


      useEffect(() => {
        if (confirmationMessage) {
            const timer = setTimeout(() => {
                setConfirmationMessage('');
            }, 3000); // Set the time duration for displaying the message

            return () => clearTimeout(timer);
        }
    }, [confirmationMessage]);


    const sendEmail = async () => {

        try{
            const response = await axios.post('https://auto-xchange-backend-h1sm.vercel.app/sendEmail', {
            name,
            phone,
            email,
            city,
            registrationNumber,
            mileage,
            message
          });
          
          console.log(response.data);
            setConfirmationMessage('Meddelandet har skickats! Vi hör av oss snart');
            // window.location.reload();
            return response.data; // Return the response data if needed
        } catch (error) {
            console.error('Email could not be sent:', error);
            // Handle the error, e.g., display an error message to the user
            throw error; // Throw the error to be handled by the caller if needed
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when the form is submitted
        try {
            await sendEmail();
            // If the request is successful, reset form fields
            setName("");
            setEmail("");
            setPhone("");
            setCity("");
            setRegistrationNumber("")
            setMileage("");
            setMessage("");
        } catch (error) {
            console.error('Email could not be sent:', error);
            // Handle error if needed
        } finally {
            setLoading(false); // Set loading back to false after the request is completed
        }
    };


    return (
        <div className='sellcarvarderingmain'>
            <h3 className='SellcarHeader'>Bil Värdering</h3>
            <h5 className='mb-4'>Vi förstår värdet av din bil och vill se till att du får det bästa erbjudandet på marknaden. Därför erbjuder vi en stark prisgaranti. Fyll i formuläret nedan så hör vår personal av sig inom 2 timmar med flera val. Vi erbjuder inte skampriser, våra priser är toppen på marknaden!</h5>
            {loading ? (
                                <div style={{ "alignItems": "center", "textAlign": "center", "marginTop": "20px" }}>
                                    <h2 style={{ "color": "#774d04", "fontStyle": "italic" }}> Skickar Meddelandet...</h2>
                                    <FontAwesomeIcon icon={faCircleNotch} spin size="10x" style={{ "color": "df8f06" }} />
                                </div>
                            ) : (
            <form onSubmit={handleSubmit}>
                <div className='filter-content.box'>
                    <div className='filter-grid'>
                        <div className='row filter-row'>
                            <div className='col-sm-6 col-xs-12'><input className='form-control line-component sellinput1' required type='text' placeholder='Namn' value={name} onChange={(e) => setName(e.target.value)} /></div>
                            <div className='col-sm-6 col-xs-12'><input className='form-control line-component sellinput1' required type='text' placeholder='Telefon' value={phone} onChange={(e) => setPhone(e.target.value)} /></div>
                            <div className='col-sm-6 col-xs-12'><input htmlFor="email" type="email" className='form-control line-component sellinput1' required placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} /></div>
                            <div className='col-sm-6 col-xs-12'><input className='form-control line-component sellinput1' type='text' required placeholder='Stad' value={city} onChange={(e) => setCity(e.target.value)} /></div>
                            <div className='col-sm-6 col-xs-12'><input className='form-control line-component sellinput1' type='text' required placeholder='Registeringsnummer' value={registrationNumber} onChange={(e) => setRegistrationNumber(e.target.value)} /></div>
                            <div className='col-sm-6 col-xs-12'><input className='form-control line-component sellinput1' type='text' required placeholder='Mätarställning' value={mileage} onChange={(e) => setMileage(e.target.value)} /></div>
                            <div className='col-sm-12 col-xs-12'><textarea className='form-control line-component sellinput-textarea' name='message' rows={5} cols={30} type='text' placeholder='Bilens Skick, Eventuella fel/skador/rost' value={message} onChange={(e) => setMessage(e.target.value)}></textarea></div>
                            <div className='col-sm-12 col-xs-12'><Button className='btn-success btn-lg mt-2 w-100' type='submit'>Skicka Förfrågan</Button></div>
                        </div>
                    </div>
                </div>
            </form>
            )}
        </div>
    );
}

export default Sellcar;