import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
//import './Car.css';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCreditCard, faTruck, faSquareCheck, faShieldHeart, faClipboardCheck, faCar, faMoneyCheckDollar, faHandshake, faCircleNotch  } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';


function Home() {
    const [showInputs, setShowInputs] = useState(true);
    const [showButton, setShowButton] = useState(false);
    const [selectedItem, setSelectedItem] = useState("sellcar");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Send email vardering
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [mileage, setMileage] = useState("");
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Home';
        window.scrollTo(0, 0);
        axios.get('https://auto-xchange-backend-h1sm.vercel.app/')
            .then((res) => {
                console.log(res.data);
                setData(res.data);
                setLoading(false); // Set loading to false when data is received

            })
            .catch((err) => {
                setError(err);
                setLoading(false); // Set loading to false when an error occurs
            });
    }, []);

    if (loading) {
        return   (
            <div style={{"alignItems":"center", "textAlign":"center", "marginBottom":"300px", "marginTop":"100px"}}>
                <h2 style={{ "color": "#774d04", "fontStyle": "italic" }}> Ett Ögonblick</h2>
                <FontAwesomeIcon icon={faCircleNotch} spin size="10x" style={{"color":"df8f06"}} />
            </div>
        );
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    const handleShowInputs = () => {
        setShowInputs(true);
        setShowButton(false);
        setSelectedItem("sellcar");
    };

    const handleShowButton = () => {
        setShowInputs(false);
        setShowButton(true);
        setSelectedItem("buycar");
    };

    const sendEmail = () => {

        axios
            .post('https://auto-xchange-backend-h1sm.vercel.app/sendEmail', {
                name,
                phone,
                email,
                city,
                registrationNumber,
                mileage,
                message,
            })
            .then((response) => {
                console.log(response.data);
                // Add any further logic here, e.g., showing a confirmation message to the user
                window.location.reload();
            })
            .catch((error) => {
                console.error('Email could not be sent:', error);
                // Handle the error, e.g., display an error message to the user
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        sendEmail();
    };

    const gotobuycar = (e) => {
        e.preventDefault();
        navigate("/kopabil");
    }

    const gotoverkstad = (e) => {
        e.preventDefault();
        navigate("/verkstad");
    }

    

    return (
        <div style={{ "overflow": "hidden" }}>
            
            <div className='top-image'>
                <div className='black-filter'>
                    <div className='Container filter-box-wrapper'>
                        <div className='filter-box '>
                            <ul className='switch-buttons nav nav-tabs'>

                                <li className={`nav-items col-sm-6 col-xs-6 ${selectedItem === 'sellcar' ? 'active' : ''}`} onClick={handleShowInputs}>
                                    <p className='nav-link'>Sälja/Värdera Bil</p>
                                </li>
                                <li className={`nav-items col-sm-6 col-xs-6  ${selectedItem === 'buycar' ? 'active' : ''}`} onClick={handleShowButton}>
                                    <p className='nav-link'>Köpa Bil</p>
                                </li>
                            </ul>
                            <div className='content'>
                                {showInputs && (
                                    <form onSubmit={handleSubmit}>
                                        <div className='filter-content.box'>
                                            <div className='filter-grid'>
                                                <div className='row filter-row'>
                                                    <div className='col-sm-6 col-xs-12'><input className='form-control line-component sellinput1' required type='text' placeholder='Namn' value={name} onChange={(e) => setName(e.target.value)} /></div>
                                                    <div className='col-sm-6 col-xs-12'><input className='form-control line-component sellinput1' required type='text' placeholder='Telefon' value={phone} onChange={(e) => setPhone(e.target.value)} /></div>
                                                    <div className='col-sm-6 col-xs-12'><input htmlFor="email" type="email" className='form-control line-component sellinput1' required placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} /></div>
                                                    <div className='col-sm-6 col-xs-12'><input className='form-control line-component sellinput1' type='text' required placeholder='Stad' value={city} onChange={(e) => setCity(e.target.value)} /></div>
                                                    <div className='col-sm-6 col-xs-12'><input className='form-control line-component sellinput1' type='text' required placeholder='Registeringsnummer' value={registrationNumber} onChange={(e) => setRegistrationNumber(e.target.value)} /></div>
                                                    <div className='col-sm-6 col-xs-12'><input className='form-control line-component sellinput1' type='text' required placeholder='Mätarställning' value={mileage} onChange={(e) => setMileage(e.target.value)} /></div>
                                                    <div className='col-sm-12 col-xs-12 computervisible_textarea'><textarea className='form-control line-component sellinput-textarea' name='message' rows={4} cols={30} type='text' placeholder='Bilens Skick, Eventuella fel/skador/rost' value={message} onChange={(e) => setMessage(e.target.value)}></textarea></div>

                                                    <div className='col-sm-12 col-xs-12 phonevisible_textarea'><textarea className='form-control line-component sellinput-textarea' name='message' rows={2} cols={30} type='text' placeholder='Bilens Skick, Eventuella fel/skador/rost' value={message} onChange={(e) => setMessage(e.target.value)}></textarea></div>

                                                    <div className='col-sm-12 col-xs-12'><Button className='btn-success btn-lg mt-2 w-100' type='submit'>Skicka Förfrågan</Button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )}

                                {showButton && (
                                    /** Köpa bil code below*/
                                    <>
                                        <div className='filter-content.box'>
                                            <div className='filter-grid'>

                                                <div className='row filter-row'>

                                                    <div className='my-4 col-sm-5 col-xs-12'><FontAwesomeIcon icon={faCheck} style={{ color: "#06ed02", marginRight: "10px" }} />Hemleverans inom 48 timmar</div>
                                                    <div className='my-4 col-sm-3 col-xs-12'><FontAwesomeIcon icon={faCheck} style={{ color: "#06ed02", marginRight: "10px" }} />Räntefritt Lån</div>
                                                    <div className='my-4 col-sm-3 col-xs-12'><FontAwesomeIcon icon={faCheck} style={{ color: "#06ed02", marginRight: "10px" }} />Försäkring</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='text-center'><Button className='btn-showallcar btn-success btn-lg mt-5' onClick={(e) => { gotobuycar(e) }}>Se Våra Bilar</Button></div>

                                    </>
                                )}
                            </div>
                        </div> {/*filter box*/}
                    </div>
                </div>
            </div>


            {/* list our car*/}


            <div style={{ "backgroundColor": "#fff9f1" }}>
                <div className="row ourserviceMainDiv">
                <div className='senastebilardiv'><h2 className='senastebilarstyle'><u>Upptäck Våra Senaste Bilar och Njut Av Körningen</u></h2>
                    <div className='seanstebilarknapp'><Button className='btn btn-info' onClick={(e) => { gotobuycar(e) }}>Visa alla bilar</Button></div></div>
                    {data
                        .sort((a, b) => b.idcar - a.idcar)
                        .filter(item => item.image8 !== null || item.image9 !== null || item.image10 !== null)
                        .slice(0, 3)
                        .map((item, index) => (
                            <div key={index} className="col-sm-4 col-xs-6 swiper-slide mb-4">
                                <Link to={`/buycar/${item.idcar}`} className="car_linkA">
                                    <div className="imgContainer___BjVIZ watermark___1OwwC">


                                    {item.imageUrls.length > 0 && (
                                            <img
                                                src={item.imageUrls[0]} // Display only the first image URL
                                                alt=""
                                                style={{ "loading": "lazy" }}
                                                className="img___2b98c"
                                            />
                                        )}


                                    </div>

                                    <div className="carinfo">
                                        <div className="carinfotitle">
                                            <h2 className="carinfotitleh2 carinfotitleprice">{item.carname}</h2>
                                            <div className="carinfoprice carinfotitleprice">{item.price} kr</div>
                                        </div>

                                        <div className="carinfosubtitle2 carinfosubtitle1">
                                            <h2 className="carinfosubtitleheading1">{item.subcarname}</h2>
                                            <div className="carinfosubtitlefromprice">{item.registrationnumber}</div>
                                        </div>

                                        <div className="carinfsecondsub">
                                            <div className="carinfosecondsub2">
                                                <ul className="carinfosecondsubul">
                                                    <li className="carinfosecondsubli">{item.yearmodel}</li>
                                                    <span className="specdot">•</span>
                                                    <li className="carinfosecondsubli">{item.fueltype}</li>
                                                </ul>
                                                <ul className="carinfosecondsubul">
                                                    <li className="carinfosecondsubli">{item.mileage}</li>
                                                    <span className="specdot">•</span>
                                                    <li className="carinfosecondsubli">{item.gearbox}</li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="seeCarButtonContainer">
                                        <button className="seeCarButton">
                                            Visa den här bilen
                                        </button>
                                    </div>

                                </Link>
                            </div>))}

                            <div className='seanstebilarknappphone'><Button className='btn btn-info mb-3' onClick={(e) => { gotobuycar(e) }}>Visa alla bilar</Button></div>
                </div>
            </div>


            {/*Section  1: Billån*/}
            <div className='row mb-2 carloandirection' style={{ "backgroundColor": "#fff9f1" }}>

                <div className='col-lg-6 col-sm-12 col-xs-12 ourservicediv'>
                    <div>
                        <h2 className='reklamheadingtext'>Upptäck Vårt MyMoney Lån - 0% Ränta, 0% Kontantinsats</h2>
                        <p className='bilverkstad-text-center'>Äger din drömbil är enklare än någonsin med vårt MyMoney-lån. Få fördelarna:</p>
                        <ul className='ulicastyle'>
                            <li className='lidotstyle'>0% Ränta: Inga räntekostnader, så din bil är ännu mer prisvärd.</li>
                            <li className='lidotstyle'>0% Kontantinsats: Kom bakom ratten utan någon initial kostnad.</li>
                            <li className='lidotstyle'>Upp till 24 månaders delbetalning: Flexibel betalningsplan som passar dig.</li>
                        </ul>
                        <p className='bilverkstad-text-center'>Skapa dina egna vägminnen med din nya bil. Besök oss idag och låt oss hjälpa dig att hitta den perfekta bilen.</p>
                        <a href='https://mymoney.se/fragor' target='_blank' rel='noopener noreferrer'><Button className='btn btn-info mb-3'>Mer Om MyMoney</Button></a>
                    </div>
                </div>
                <div className='col-lg-6 col-sm-12 col-xs-12 paddingzero mb-2'>
                    <div className='mymoneyImage'></div>

                </div>

            </div>



              {/* När du Köper en bil*/}
              <div  style={{ "backgroundColor": "#000000" }} className='my-2'>
                <h3 className='whenbuymainheader'><u>Köp med Förtorende</u></h3>
                <div className='row whenbuycarstyle mx-2'>
                <div className='col-lg-2 whenbuycenter'>
                    <FontAwesomeIcon icon={faTruck} style={{"minHeight":"50px", "color":"#df8f06" }} />
                        <h2 style={{"color":"#ffffff"}} className='whenbuyheaderstyle'>Leverans</h2>
                        <p style={{"color":"#ffffff"}}>Upptäck Vår Exklusiva Hemleverans i Hela Sverige – Levereras Inom 48 Timmar</p>
                    </div>

                    <div className='col-lg-2 whenbuycenter'>
                    <FontAwesomeIcon icon={faCreditCard} style={{"minHeight":"50px", "color":"#df8f06" }} />
                        <h2 style={{"color":"#ffffff"}} className='whenbuyheaderstyle'>Finansering</h2>
                        <p style={{"color":"#ffffff"}}>Ingen Kontantinsats: Upp Till 100 000 kr Räntefritt Lån med Möjlighet till Delbetalning upp till 24 Månader</p>
                    </div>

                    <div className='col-lg-2 whenbuycenter'>
                    <FontAwesomeIcon icon={faShieldHeart} style={{"minHeight":"50px", "color":"#df8f06" }} />
                        <h2 style={{"color":"#ffffff"}} className='whenbuyheaderstyle'>Försäkring</h2>
                        <p style={{"color":"#ffffff"}}>14 Dagar Gratis Försäkring – En Extra Förmån Vid Köp av Många av Våra Bilar.</p>
                    </div>

                    <div className='col-lg-2 whenbuycenter'>
                    <FontAwesomeIcon icon={faSquareCheck} style={{"minHeight":"50px", "color":"#df8f06" }} />
                        <h2 style={{"color":"#ffffff"}} className='whenbuyheaderstyle'>Granskning</h2>
                        <p style={{"color":"#ffffff"}}>Provkörning och Noggrann kontroll Innan Köp - Din Garanti för Nöjd Bilaffär</p>
                    </div>
                </div>
            </div>


            {/*Sektion 2 Ica försäkring*/}
            <div className='row mb-2' style={{ "backgroundColor": "#fff9f1", "paddingtop": "0px" }}>

                <div className='col-lg-6 col-sm-12 col-xs-12 my-2'>
                    <div className='icaImage'></div>
                </div>

                <div className='col-lg-6 col-sm-12 col-xs-12 ourservicediv'>
                    <div>
                        <h2 className='reklamheadingtext'>Försäkring Genom ICA</h2>
                        <p className='bilverkstad-text-center'>Vi erbjuder en unik förmån för våra kunder genom att tillhandahålla 14 dagars kostnadsfri
                            försäkring via ICA Försäkring för de flesta bilar i vår kollektion. Denna extra trygghet,
                            som AutoXChange generöst står för, syftar till att säkerställa att våra kunder känner sig helt bekväma
                            när de väljer oss som sin föredragna leverantör av kvalitetsbilar.</p>
                            <a href='https://www.icaforsakring.se/vanliga-fragor/' target='_blank' rel='noopener noreferrer'><Button className='btn btn-info mb-3'>Mer om Ica Försäkring</Button></a>
                    </div>
                </div>


            </div>



             {/*Sälj eller Förmedla Din Bil*/}
             <div  style={{ "backgroundColor": "#000000" }} className='my-2'>
                <h3 className='whenbuymainheader'><u>Sälj eller Förmedla Din Bil</u></h3>
                <div className='row whenbuycarstyle mx-2'>
                <div className='col-lg-2 whenbuycenter'>
                    <FontAwesomeIcon icon={faCar} style={{"minHeight":"50px", "color":"#df8f06" }} />
                        <h2 style={{"color":"#ffffff"}} className='whenbuyheaderstyle'>Sälj Din Bil</h2>
                        <p style={{"color":"#ffffff"}}>Vi Köper Alla Begagnade Bilar – Oavsett Pris eller Modellår. Garanterad Högsta Värdering på Marknaden</p>
                    </div>

                    <div className='col-lg-2 whenbuycenter'>
                    <FontAwesomeIcon icon={faHandshake} style={{"minHeight":"50px", "color":"#df8f06" }} />
                        <h2 style={{"color":"#ffffff"}} className='whenbuyheaderstyle'>Förmedla</h2>
                        <p style={{"color":"#ffffff"}}>Bästa Förmedlingsförslaget - Högsta Värderingen För Din Bil. 0 kr Avgift Vid Förmedling</p>
                    </div>

                    <div className='col-lg-2 whenbuycenter'>
                    <FontAwesomeIcon icon={faClipboardCheck} style={{"minHeight":"50px", "color":"#df8f06" }} />
                        <h2 style={{"color":"#ffffff"}} className='whenbuyheaderstyle'>Upphämtning</h2>
                        <p style={{"color":"#ffffff"}}>Bilhämtning i Hela Sverige När Du Säljer eller Förmedlar Din Bil.</p>
                    </div>

                    <div className='col-lg-2 whenbuycenter'>
                    <FontAwesomeIcon icon={faMoneyCheckDollar} style={{"minHeight":"50px", "color":"#df8f06" }} />
                        <h2 style={{"color":"#ffffff"}} className='whenbuyheaderstyle'>Utbetalning</h2>
                        <p style={{"color":"#ffffff"}}>Flexibel Utbetalning: Välj Mellan Kontant, Swish eller Banköverföring. Snabb Pengaöverföring samma dag som du säljer din bil.</p>
                    </div>
                </div>
            </div>



            {/*Section  3: Verkstad*/}
            <div className='row carloandirection pb-3' style={{ "backgroundColor": "#fff9f1" }}>

                <div className='col-lg-6 col-sm-12 col-xs-12 ourservicediv'>
                    <div>
                        <h2 className='reklamheadingtext'>Vår Bilverkstad - AutoXchange</h2>
                        <p className='bilverkstad-text-center'>Vi tar hand om allt, från bilkontroller och service till hjulbyten och avancerad felsökning. Våra experter utför även kamremsbyten och andra mekaniska och elektriska arbeten.</p>

                        <p className='bilverkstad-text-center'>Vi garanterar högkvalitativt arbete och erbjuder professionell service till ett förmånligt pris. Besök vår verkstad och låt oss hålla din bil i toppskick!</p>
                        <Button className='btn btn-info mb-3' onClick={(e) => { gotoverkstad(e) }}>Kontakta Verkstad</Button>
                    </div>
                </div>
                <div className='col-lg-6 col-sm-12 col-xs-12 paddingzero mt-2'>
                    <div className='verkstadimage'></div>

                </div>

            </div>
            


        </div>
    );
}


export default Home;