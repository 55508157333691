import React, { useState, useLayoutEffect, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Contact.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import image1 from '../Images/contact.jpg'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faPhone } from '@fortawesome/free-solid-svg-icons'
function Contact() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');


    useLayoutEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);

      useEffect(() => {
        if (confirmationMessage) {
            const timer = setTimeout(() => {
                setConfirmationMessage('');
            }, 3000); // Set the time duration for displaying the message

            return () => clearTimeout(timer);
        }
    }, [confirmationMessage]);

    const askquestion = async() => {
        try{
            const response = await axios.post('https://auto-xchange-backend-h1sm.vercel.app/askquestion', {
            name,
            email,
            message,
          });
          
          console.log(response.data);
            setConfirmationMessage('Meddelandet har skickats! Vi hör av oss snart');
            // window.location.reload();
            return response.data; // Return the response data if needed
        } catch (error) {
            console.error('Email could not be sent:', error);
            // Handle the error, e.g., display an error message to the user
            throw error; // Throw the error to be handled by the caller if needed
        }
    };

    const askquestionsendofrm = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when the form is submitted
        try {
            await askquestion();
            // If the request is successful, reset form fields
            setName("");
            setEmail("");
            setMessage("");
        } catch (error) {
            console.error('Email could not be sent:', error);
            // Handle error if needed
        } finally {
            setLoading(false); // Set loading back to false after the request is completed
        }
    };

    return (
        <section className="">
            <Container className='my-4'>
                <Row className="justify-content-between custom-row d-flex flex-wrap">
                    <Col  className="custom-col">
                        <Col>
                            <h3>Här Finns vi</h3>
                            <p>Cypressvägen 10A, 21363 Malmö</p>
                        </Col>
                        <Col className='mapstyle'><img src={image1} className='mapimage' alt=''/></Col>
                    </Col>

                    <Col  className="custom-col">
                    {loading ? (
                                <div style={{ "alignItems": "center", "textAlign": "center", "marginTop": "20px" }}>
                                    <h2 style={{ "color": "#774d04", "fontStyle": "italic" }}> Skickar Meddelandet...</h2>
                                    <FontAwesomeIcon icon={faCircleNotch} spin size="10x" style={{ "color": "df8f06" }} />
                                </div>
                            ) : (
                        <Form id="contact-form" method="post" className="form" onSubmit={askquestionsendofrm}>
                            <Row>
                                <Col>
                                    <h3 className="mb-lg-2">Hur kan vi hjälpa dig?</h3>
                                    <p className="mb-4">Kontakta oss mer än gärna. Vi svarar alltid inom 3 timmar.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label htmlFor="name">Namn</Form.Label>
                                        <Form.Control type="text" name="name" placeholder="Namn" required className="form-control form-control-underlined color-text-base" value={name} onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label htmlFor="email">E-postadress</Form.Label>
                                        <Form.Control type="email" name="email" placeholder="E-postadress@domain.com" value={email} onChange={(e) => setEmail(e.target.value)} required className="form-control form-control-underlined color-text-base" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group>
                                <Form.Label htmlFor="message" className='mt-2'>Meddelande</Form.Label>
                                <Form.Control as="textarea" rows={4} name="message" placeholder="Skriv ditt meddelande här..." value={message} onChange={(e) => setMessage(e.target.value)} required className="form-control form-control-underlined color-text-base" />
                            </Form.Group>

                            <Button variant="outline-primary" className='mt-2 mb-2' type='submit'>
                                Skicka
                            </Button>
                        </Form>
                         )}
                         <h2 style={{ "color": "green", "fontStyle": "italic", "marginBottom": "15px" }}>{confirmationMessage}</h2>
                        <Col>
                        {/* <p style={{"width":"fitcontent"}}><a href='tel:0762656575' style={{ "color": "black", "textDecoration": "unset" }}><FontAwesomeIcon style={{ "marginRight": "10px", "color": "#4ed11f" }} icon={faPhone} />076 265 6575</a></p> */}
                       <p style={{"width":"fitcontent"}}> <a href='tel:0708182583' style={{ "color": "black", "textDecoration": "unset" }}><FontAwesomeIcon style={{ "marginRight": "10px", "color": "#4ed11f" }} icon={faPhone} />070 818 2583</a></p>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Contact;