import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const carOptionsData = [
    { id: 1, name: 'Bluetooth' },
    { id: 2, name: 'Servicebok' },
    { id: 3, name: '2 Nycklar' },
    { id: 4, name: 'El-speglar Fram' },
    { id: 5, name: 'Besiktad' },
    { id: 6, name: 'Elektrisk Baklucka' },
    { id: 7, name: 'MSDäck' },
    { id: 8, name: 'Sommar & Vinterdäck' },
    { id: 9, name: 'Finansering' },
    { id: 10, name: 'Svensk Såld' },
    { id: 11, name: 'Komplett Servicebok' },
    { id: 12, name: 'Farthålalre' },
    { id: 13, name: 'Type C Uttag' },
    { id: 14, name: 'Aux' },
    { id: 15, name: 'USB Uttag' },
    { id: 16, name: '12V Uttag' },
    { id: 17, name: 'Touch Skärm' },
    { id: 19, name: 'Navigator' },
    { id: 20, name: 'Sätesvärme Fram' },
    { id: 21, name: 'Sätesvärme Bak' },
    { id: 22, name: 'Isofix Fästen' },
    { id: 23, name: 'Multifunktionsratt' },
    { id: 24, name: 'Start & Stop Funktion' },
    { id: 25, name: 'Elstol' },
    { id: 26, name: 'Elektrisk Handbroms' },
    { id: 27, name: 'Panorama Tak' },
    { id: 29, name: 'Taklucka' },
    { id: 30, name: 'Bakkamera' },
    { id: 31, name: 'Baksensorer' },
    { id: 32, name: 'Framsensorer' },
    { id: 33, name: 'Helskin Klädsel' },
    { id: 34, name: 'Sportstolar' },
    { id: 35, name: 'Autohold' },
    { id: 36, name: 'Regnsensorer' },
    { id: 37, name: 'AC' },
    { id: 38, name: 'Separat AC' },
    { id: 39, name: 'Uppvärmda Sidospeglar' },
    { id: 40, name: 'Infällbara Sidospeglar' },
    { id: 41, name: 'Döda Vinkel Varning' },
    { id: 42, name: 'Dragkrok' },
    { id: 43, name: 'Android Auto' },
    { id: 44, name: 'Apple Carplay' },
    { id: 45, name: 'Keyless Entry' },
    { id: 46, name: 'Keyless Start' },
    { id: 47, name: 'Tonade Rutor' },
    { id: 48, name: 'Xenon Strålkastare' },
    { id: 49, name: 'Helljus Led' },
    { id: 50, name: 'Bakljus Led' },
    { id: 51, name: 'Avblandand Bakspegel' },
    { id: 52, name: 'Premium Ljudsystem' },
    { id: 53, name: 'Körfältsassistans' },
    { id: 54, name: 'Reservhjul' },
    { id: 55, name: 'Miljö Klass Euro 5' },
    { id: 56, name: 'Miljö Klass Euro 6' },
    { id: 57, name: 'Barnlås' },
    // Lägg till fler alternativ här
];

function AdminAddCar() {

    const [carName, setCarName] = useState("");
    const [yearmodel, setYearModel] = useState("");
    const [mileage, setMileage] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [gearbox, setGearBox] = useState("Automat");
    const [numberOfPlaces, setNumberOfPlaces] = useState("5");
    const [fueltype, setFuelType] = useState("Bensin");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [isSold, setIsSold] = useState("Nej");

    // const [image2, setImage2] = useState("");

    const [subcarname, setSubCarName] = useState("");

    //  const [selectedImages, setSelectedImages] = useState([]);
    const [file, setFile] = useState(null);

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleFileChange = (e) => {
        const files = e.target.files;
        setFile(files);
        //setFile(e.target.files);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        for (let i = 0; i < file.length; i++) {
            formData.append('image', file[i]);
        }
        const numberOfSeats = parseInt(numberOfPlaces, 10);
        formData.append('carName', carName);
        formData.append('yearmodel', yearmodel);
        formData.append('mileage', mileage);
        formData.append('description', description);
        formData.append('price', price);
        formData.append('gearbox', gearbox);
        formData.append('numberOfPlaces', numberOfSeats);
        formData.append('fueltype', fueltype);
        formData.append('registrationNumber', registrationNumber);
        formData.append('subcarname', subcarname);
        formData.append('caroptions', selectedOptions);
        formData.append('issold', isSold);
        try {
            const response = await axios.post('https://auto-xchange-backend-h1sm.vercel.app/addcar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Car and images uploaded successfully:', response.data);
            navigate("/AdminHomePage");
        } catch (error) {
            console.error('Car and image upload failed:', error);
        }

    };

    const navigate = useNavigate();

    const styles = {

        footer: {
            minHeight: "76vh",
            paddingBottom: "10px",

        }
    }

    const back = () => {
        navigate("/AdminHomePage");
    }



    const handleCheckboxChange = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };


    return (
        <div className='card-body' style={styles.footer}>
            <button className='btn btn-warning mb-2' onClick={(e) => back(e)}>Tillbaka</button>
            <form>

                <div className='row m-3'>
                    <div className='form-group mb-2 col-lg-6'>
                        <label className='form-label fw-bold'>Bilnamn</label>
                        <input
                            type="text"
                            placeholder='Bilnamn'
                            name="carname"
                            className='form-control'
                            value={carName}
                            onChange={(e) => setCarName(e.target.value)}
                        >
                        </input>
                    </div>

                    <div className='form-group mb-2 col-lg-6'>
                        <label className='form-label fw-bold'>Bilmodell</label>
                        <input
                            type="text"
                            placeholder='Bilmodell'
                            name="Bilmodell"
                            className='form-control'
                            value={subcarname}
                            onChange={(e) => setSubCarName(e.target.value)}
                        >
                        </input>
                    </div>



                </div>

                <div className='row m-3'>
                    <div className='form-group mb-2 col-lg-6'>
                        <label className='form-label fw-bold'>Miltal</label>
                        <input
                            type="text"
                            placeholder='Miltal'
                            name="mileage"
                            className='form-control'
                            value={mileage}
                            onChange={(e) => setMileage(e.target.value)}
                        >
                        </input>
                    </div>

                    <div className='form-group mb-2 col-lg-6'>
                        <label className='form-label fw-bold'>Årsmodell</label>
                        <input
                            type="text"
                            placeholder='Årsmodell'
                            name="yearmodel"
                            className='form-control'
                            value={yearmodel}
                            onChange={(e) => setYearModel(e.target.value)}
                        >
                        </input>
                    </div>


                </div>
                <div className='row m-3'>
                    <div className='form-group mb-2 col-lg-6'>
                        <label className='form-label fw-bold'>Pris</label>
                        <input
                            type="text"
                            placeholder='Pris'
                            name="price"
                            className='form-control'
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        >
                        </input>
                    </div>

                    <div className="form-group mb-2 col-lg-6">
                        <label className="form-label fw-bold">Växellåda</label>
                        <div>
                            <label className="form-check-label mx-2">
                                <input
                                    type="radio"
                                    name="gearbox"
                                    value="Automat"
                                    checked={gearbox === "Automat"}
                                    onChange={(e) => setGearBox("Automat")}
                                    className="form-check-input"

                                />
                                Automat
                            </label>
                            <label className="form-check-label">
                                <input
                                    type="radio"
                                    name="gearbox"
                                    value="Manuell"
                                    checked={gearbox === "Manuell"}
                                    onChange={(e) => setGearBox("Manuell")}
                                    className="form-check-input"
                                />
                                Manuell
                            </label>
                        </div>
                    </div>
                </div>

                <div className='row m-3'>
                    <div className='form-group mb-2 col-lg-6'>
                        <label className='form-label fw-bold'>RegNummer</label>
                        <input
                            type="text"
                            placeholder='RegNummer'
                            name="registrationNumber"
                            className='form-control'
                            value={registrationNumber}
                            onChange={(e) => setRegistrationNumber(e.target.value)}
                        >
                        </input>
                    </div>

                    <div className='form-group mb-2 col-lg-6'>
                        <label className='form-label fw-bold'>Beskrivning</label>
                        <input
                            type="text"
                            placeholder='Beskrivning'
                            name="description"
                            className='form-control'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        >
                        </input>
                    </div>
                </div>


                <div className='row m-3'>
                    <div className='form-group mb-2 col-lg-6'>
                        <label className='form-label fw-bold'>Antal Sittplatser</label>
                        <select
                            name="numberOfPlaces"
                            className='form-control'
                            value={numberOfPlaces}
                            onChange={(e) => setNumberOfPlaces(e.target.value)}
                        >
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                        </select>
                    </div>

                    <div className='form-group mb-2 col-lg-6'>
                        <label className='form-label fw-bold'>Bränsle</label>
                        <select
                            name="fueltype"
                            className='form-control'
                            value={fueltype}
                            onChange={(e) => setFuelType(e.target.value)}
                        >
                            <option value="Bensin">Bensin</option>
                            <option value="Diesel">Diesel</option>
                            <option value="Elektrisk">Elektrisk</option>
                        </select>
                    </div>
                </div>

                <div className='row m-3'>
                    <div className='form-group mb-2 col-lg-6'>
                        <label className='form-label mx-4 fw-bold'>Bilutrustning</label>
                        <div className="checkbox-group">
                            {carOptionsData.map((option) => (
                                <label key={option.id} style={{ marginRight: '10px' }}>
                                    <input
                                        type="checkbox"
                                        name="caroptions"
                                        value={option.name}
                                        checked={selectedOptions.includes(option.name)}
                                        onChange={() => handleCheckboxChange(option.name)}
                                        style={{ marginRight: '2px' }}
                                    />
                                    {option.name}
                                </label>
                            ))}


                        </div>
                    </div>

                    <div className="form-group mb-2 col-lg-6">
                        <label className="form-label fw-bold">Är Bilen såld?</label>
                        <div>
                            <label className="form-check-label mx-2">
                                <input
                                    type="radio"
                                    name="issold"
                                    value="Ja"
                                    checked={isSold === "Ja"}
                                    onChange={(e) => setIsSold("Ja")}
                                    className="form-check-input"

                                />
                                Ja
                            </label>
                            <label className="form-check-label">
                                <input
                                    type="radio"
                                    name="issold"
                                    value="Nej"
                                    checked={isSold === "Nej"}
                                    onChange={(e) => setIsSold("Nej")}
                                    className="form-check-input"
                                />
                                Nej
                            </label>
                        </div>
                    </div>
                </div>





                <div className='form-group m-3'>
                    <label className='form-label'>Ladda upp bilder</label>
                    <div>
                        <input type="file" onChange={handleFileChange} multiple />
                        {/* <button onClick={handleUpload}>Upload</button> */}
                    </div>
                </div>



                <Button className='btn btn-success m-3' onClick={handleSubmit}>Spara</Button>
                {/* <Link to="/AdminHomePage" className="btn btn-danger mx-1">Cancel</Link> */}
            </form>
        </div>

    )
}

export default AdminAddCar;