import React, { useState, useLayoutEffect, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './carosel.css';
import './Verkstad.css';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';

function Verkstad() {


    const [customername, setCustomerName] = useState("");
    const [email, setEmail] = useState("");
    const [regNumber, SetRegnumber] = useState("");
    //const [kindOfWork, setKindOfWork] = useState("köpa");
    const [otherWork, setOtherWork] = useState("");
    const [phoneNumber, SetPhoneNumber] = useState("");
    const [date, SetDate] = useState("");
    const [message, setMessage] = useState("");

    const [selectedOptions, setSelectedOptions] = useState([]);

    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    useEffect(() => {
        if (confirmationMessage) {
            const timer = setTimeout(() => {
                setConfirmationMessage('');
            }, 3000); // Set the time duration for displaying the message

            return () => clearTimeout(timer);
        }
    }, [confirmationMessage]);


    const askquestion = async () => {
        const kindOfWork = selectedOptions.map(option => option.value).join(', ');

        const formattedTime = date.toLocaleString('sv-SE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
        try {
            const response = await axios.post('https://auto-xchange-backend-h1sm.vercel.app/verkstad', {
                customername,
                email,
                regNumber,
                kindOfWork,
                otherWork,
                phoneNumber,
                formattedTime,
                message,
            });

            console.log(response.data);
            setConfirmationMessage('Förfrågan har skickats! Vi Hör av oss snart');
            // window.location.reload();
            return response.data; // Return the response data if needed
        } catch (error) {
            console.error('Email could not be sent:', error);
            // Handle the error, e.g., display an error message to the user
            throw error; // Throw the error to be handled by the caller if needed
        }
    };

    const askquestionsendofrm = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when the form is submitted
        try {
            await askquestion();
            // If the request is successful, reset form fields
            setCustomerName("");
            setEmail("");
            SetRegnumber("");
            setOtherWork("");
            SetPhoneNumber("");
            SetDate("");
            setMessage("");
            setSelectedOptions([]);
        } catch (error) {
            console.error('Email could not be sent:', error);
            // Handle error if needed
        } finally {
            setLoading(false); // Set loading back to false after the request is completed
        }
    };

    const handleWorkChange = (selectedOptions) => {
        // Hantera valda alternativ här
        setSelectedOptions(selectedOptions);
    };


    const workOptions = [
        { value: 'Service', label: 'Service' },
        { value: 'Hjulbyte', label: 'Hjulbyte' },
        { value: 'Felsökning', label: 'Felsökning' },
        { value: 'Reperation', label: 'Reperation' },
        { value: 'Bilkontroll', label: 'Bilkontroll' },
        { value: 'Kamremsbyte', label: 'Kamremsbyte' },
        { value: 'Databox', label: 'Databox' },
        { value: 'Rengörning Partikelfilter', label: 'Rengörning Partikelfilter' },
        { value: 'Växellåda', label: 'Växellåda' },
        { value: 'Nyckelprogrammering', label: 'Nyckelprogrammering' },
        { value: 'Programmering', label: 'Programmering' },
        { value: 'Stenskott', label: 'Stenskott' },
        { value: 'Strålkastare Polering', label: 'Strålkastare Polering' },
        { value: 'Svetsning', label: 'Svetsning' },
        { value: 'Biltvätt', label: 'Biltvätt' },
        { value: 'Rekording/Textiltvätt', label: 'Rekording/Textiltvätt' },
        { value: 'Bil Polering', label: 'Bil Polering' },
        { value: 'Övrigt', label: 'Övrigt' },

    ];



    return (
        <div>
            <div className=" verkstadmainheader" style={{ "backgroundColor": "#fff9f1" }}>
                <h3 className='verkstadwelcome'>Välkommen Till AutoXChange Bilverkstad</h3>
                <p className='m-0'>Vi på vår bilverkstad är stolta över att erbjuda en omfattande lista av tjänster för att säkerställa att din
                    bil förblir i toppskick. Våra erfarna experter hanterar både mekaniska och elektriska arbete med precision
                    och noggrannhet.</p><br />
                <p>Vi erbjuder bland annat följande tjänster:</p>
                <ul>
                    <li><span><strong>Bilkontroll:</strong></span> En grundlig genomgång för att säkerställa att varje del av din bil fungerar optimalt.</li>
                    <li><span><strong>Service:</strong></span> Regelbunden underhållsservice för att förlänga livslängden och prestandan på din bil.</li>
                    <li><span><strong>Hjulbyten:</strong></span> Professionell hjulservice för att säkerställa säkra och effektiva körupplevelser.</li>
                    <li><span><strong>Felsökning:</strong></span> Avancerad felsökning för att identifiera och lösa eventuella problem med din bil.</li>
                    <li><span><strong>Kamremsbyte:</strong></span> Precist och punktligt byte av kamrem för att säkerställa optimal motorprestanda.</li>
                    <li><span><strong>Övrigt:</strong></span> Allt mekaniskt och elektriskt arbete, inklusive partikelfilter rengörning, växellåda, databox, programmering och svetsning.</li>
                </ul>

                <p>Vi utför allt med skicklighet och professionalism, och vi är så övertygade om vårt arbete att vi ger garanti på varje tjänst.</p>
                <p>För ytterligare frågor eller specialiserade behov, kontakta oss genom formuläret eller ring oss direkt. Vi är här för att svara på dina funderingar och erbjuda en trygg resa på vägarna.</p>
                <p>Varmt välkommen till vår verkstad för högkvalitativt bilunderhåll till överkomliga priser.</p>
            </div>




            <div>

                <div className='row car-page_margin mt-0 pt-4 contactdivcode'>
                    <div className='col-lg-4'>
                        <h2 className='carDetailsMainHeader'><u>Snabbt och Enkelt – Få Svar Direkt</u></h2>
                        <p>
                            Fyll i formuläret och välj önskat arbete. Vi återkopplar direkt med pris och relevant information.
                            Alternativt är du välkommen att ringa oss om du föredrar personlig kontakt. Vi ser fram emot att hjälpa dig med din bilservice.
                            Observera att den tid du väljer i formuläret är din önskade tid. Vi återkopplar för att bekräfta om den valda tiden är
                            tillgänglig eller föreslå närmast tillgängliga tid.</p>
                        {/* <p style={{ "width": "fitcontent" }}><a href='tel:0762656575' style={{ "color": "black", "textDecoration": "unset" }}><FontAwesomeIcon style={{ "marginRight": "10px", "color": "#4ed11f" }} icon={faPhone} />076 265 6575</a></p> */}
                        <p style={{ "width": "fitcontent" }}><a href='tel:0708182583' style={{ "color": "black", "textDecoration": "unset" }}><FontAwesomeIcon style={{ "marginRight": "10px", "color": "#4ed11f" }} icon={faPhone} />070 818 2583</a></p>
                    </div>

                    <div className='col-lg-7'>



                        <div className='kontaktaoss-main' id='kontaktforum'>
                            {loading ? (
                                <div style={{ "alignItems": "center", "textAlign": "center", "marginTop": "20px" }}>
                                    <h2 style={{ "color": "#774d04", "fontStyle": "italic" }}> Skickar Förfrågan...</h2>
                                    <FontAwesomeIcon icon={faCircleNotch} spin size="10x" style={{ "color": "df8f06" }} />
                                </div>
                            ) : (
                                <Form id="contact-form" method="post" className="form" onSubmit={askquestionsendofrm}>
                                    <Row className='contactus-margin'>
                                        <Col sm={6} className='phone-margin-formular'>
                                            <Form.Group>

                                                <Form.Control type="text" name="name" placeholder="Namn *" required className="form-control form-control-underlined color-text-base" value={customername} onChange={(e) => setCustomerName(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group>

                                                <Form.Control type="email" name="email" placeholder="E-postadress@domain.com *" value={email} onChange={(e) => setEmail(e.target.value)} required className="form-control form-control-underlined color-text-base" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {/* Phone number and city */}
                                    <Row className='contactus-margin'>
                                        <Col sm={6} className='phone-margin-formular'>
                                            <Form.Group>

                                                <Form.Control type="tel" name="phoneNumber" placeholder="Telefonnummer *" required className="form-control form-control-underlined color-text-base" value={phoneNumber} onChange={(e) => SetPhoneNumber(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group>

                                                <Form.Control type="text" name="name" placeholder="Registeringsnummer på din bil *" required className="form-control form-control-underlined color-text-base" value={regNumber} onChange={(e) => SetRegnumber(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {/* regnummer & Datum */}
                                    <Row className='contactus-margin'>
                                        <Col sm={6} className='phone-margin-formular'>
                                            <Form.Group>
                                                <Form.Label>Önskat datum och tid för bilinlämning *</Form.Label>
                                                <DatePicker
                                                    selected={date}
                                                    onChange={(date) => SetDate(date)}
                                                    showTimeSelect
                                                    dateFormat="Pp"
                                                    //timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    // dateFormat="yyyy-MM-dd HH:mm"
                                                    placeholderText="Välj datum och tid"
                                                    className="form-control form-control-underlined color-text-base"
                                                    wrapperClassName="datePicker"
                                                    required

                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col sm={6}>
                                            <Form.Label>Välj typ av arbete:</Form.Label>
                                            <Select
                                                isMulti
                                                options={workOptions}
                                                onChange={handleWorkChange}
                                                value={selectedOptions}
                                            />
                                        </Col>
                                    </Row>

                                    {/* typ av arbete */}


                                    {/* If i choose change car */}
                                    {selectedOptions.map(option => option.value).includes('Övrigt') && (
                                        <Row className='contactus-margin'>
                                            <Col sm={12}>
                                                <Form.Group>
                                                    <Form.Label>Beskriv arbetet du vill utföra</Form.Label>
                                                    <Form.Control type="text" name="otherWork" placeholder="Till exempel:Hjulinstllning, Nyckelprogrammering ... *" required className="form-control form-control-underlined color-text-base" value={otherWork} onChange={(e) => setOtherWork(e.target.value)} />
                                                </Form.Group>
                                            </Col>

                                        </Row>)}


                                    <Form.Group>

                                        <Form.Control as="textarea" rows={4} name="message" placeholder="Vänligen skriv dina kommentarer eller frågor här. Du kan också beskriva arbetet mer specifikt" value={message} onChange={(e) => setMessage(e.target.value)} className="form-control form-control-underlined color-text-base" />
                                    </Form.Group>

                                    <Button variant="outline-success" className='mt-2 mb-2' type='submit'>
                                        Lämna in
                                    </Button>
                                </Form>
                            )}
                            <h2 style={{ "color": "green", "fontStyle": "italic", "marginBottom": "15px" }}>{confirmationMessage}</h2>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Verkstad;