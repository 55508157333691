import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Admin.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {Button, Col, Container, Form, Row} from "react-bootstrap";


function AdminLogin() {

    const [luserName, setLUserName] = useState("");
    const [luserPassword, setLUserPassword] = useState("");
    //const [loginStatus, setloginStatus] = useState("");
    const navigate = useNavigate();

    const login = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://auto-xchange-backend-h1sm.vercel.app/adminLogin', {
                luserName,
                luserPassword,
            });
            const  token  = response.data;
            console.log(token)
              // Store the token securely (e.g., in localStorage)
      localStorage.setItem('token', token);
      console.log("localstorage " + localStorage.getItem('token'));
      navigate("/AdminhomePage");
      // Redirect or handle the authenticated user as needed
    } catch (error) {
      console.error(error);
    }
    }

    const styles = {

        footer: {
            minHeight:"76vh",
            paddingBottom: "10px",

        }
    }

    return (
        <div className='card-body' style={styles.footer}>
            
        
            <Container>
                <h1 className="shadow-sm text-success mt-5 p-3 text-center rounded">Admin Login</h1>
                <Row className="mt-5">
                    <Col lg={5} md={6} sm={12} className="p-5 m-auto shadow-sm rounded-lg">
                        <Form>
                            <Form.Group controlId="formBasicEmail" className='mb-2'>
                                <Form.Label>Namn</Form.Label>
                                <Form.Control type="text" placeholder="Enter name" name='luserName' value={luserName}  onChange={(e) => setLUserName(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Lösenord</Form.Label>
                                <Form.Control type="password" placeholder="Password" name="luserPassword"  value={luserPassword} onChange={(e) => setLUserPassword(e.target.value)} />
                            </Form.Group>

                            <Button variant="success btn-block mt-3" onClick={(e) => login(e)} >
                                Logga In
                            </Button>
                            {/* <h5 className='text-danger mt-2'>{loginStatus}</h5> */}
                        </Form>
                    </Col>
                </Row>
                <div className="mb-5"></div>
            </Container>

        
        </div>

        
    )
}

export default AdminLogin;