import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './BuyCarOuterMenu.css';
import './Car.css';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link from React Router
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

function BuyCarOuterMenu(){

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        document.title = 'Köpa Bil';
        window.scrollTo(0, 0);
        axios.get('https://auto-xchange-backend-h1sm.vercel.app/')
            .then((res) => {
                console.log(res.data);
                setData(res.data);
                setLoading(false); // Set loading to false when data is received

            })
            .catch((err) => {
                setError(err);
                setLoading(false); // Set loading to false when an error occurs
            });
    }, []);

    if (loading) {
        return   (
            <div style={{"alignItems":"center", "textAlign":"center", "marginBottom":"300px", "marginTop":"100px"}}> 
               <h2 style={{ "color": "#774d04", "fontStyle": "italic" }}> Ett Ögonblick</h2>
                <FontAwesomeIcon icon={faCircleNotch} spin size="10x" style={{"color":"df8f06"}} />
            </div>
        );
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return(
        <div className="row my-4 mx-2 ">
        <h2 className='text-center mb-2 mt-4 fw-bold text-uppercase fst-italic' style={{"color":"#003e70"}}>Utforska vår fordonskollektion och upplev en körning i världsklass</h2>
        <p className='text-center mb-4'>Upptäck vårt breda urval av begagnade bilar, noggrant granskade och med flera flexibla finansieringsalternativ tillgängliga. Gör en klok investering och hitta din kommande bil bland våra begagnade fordon, och boka en provkörning för att uppleva den själv.</p>
        {data
        .sort((a, b) => (a.issold === 'Nej' ? -1 : 1))
        .map((item, index) => (
            <div key={index} className="col-sm-4 col-xs-6 swiper-slide mb-4">
                <Link to={`/buycar/${item.idcar}`} className="car_linkA">
                    <div className="imgContainer___BjVIZ watermark___1OwwC">

                    {item.imageUrls.length > 0 && (
                                            <img
                                                src={item.imageUrls[0]} // Display only the first image URL
                                                alt=""
                                                style={{ "loading": "lazy" }}
                                                className="img___2b98c"
                                            />
                                        )}
                    
                    </div>

                    <div className="carinfo">
                        <div className="carinfotitle">
                            <h2 className="carinfotitleh2 carinfotitleprice">{item.carname}</h2>
                            <div className="carinfoprice carinfotitleprice">{item.price} kr</div>
                        </div>

                        <div className="carinfosubtitle2 carinfosubtitle1">
                            <h2 className="carinfosubtitleheading1">{item.subcarname}</h2>
                            <div className="carinfosubtitlefromprice">{item.registrationnumber}</div>
                        </div>

                        <div className="carinfsecondsub">
                            <div className="carinfosecondsub2">
                                <ul className="carinfosecondsubul">
                                    <li className="carinfosecondsubli">{item.yearmodel}</li>
                                    <span className="specdot">•</span>
                                    <li className="carinfosecondsubli">{item.fueltype}</li>
                                </ul>
                                <ul className="carinfosecondsubul">
                                    <li className="carinfosecondsubli">{item.mileage}</li>
                                    <span className="specdot">•</span>
                                    <li className="carinfosecondsubli">{item.gearbox}</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="seeCarButtonContainer">
                        <button className="seeCarButton">
                            Visa den här bilen
                        </button>
                    </div>

                </Link>
            </div>))}
    </div>
    );
}

export default BuyCarOuterMenu;