import { useLayoutEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

    const AboutUs = () => {

      const { pathname } = useLocation();
      useLayoutEffect(() => {
          window.scrollTo(0, 0);
        }, [pathname]);
  
        return (
            <div>
              <section className="py-4">
                <Container className='mt-4'>
                  <Row className="justify-content-between">
                    <Col lg={6} xs={12} className="mb-4">
                      <h3 className="mb-lg-2">Vår Uppväxt i Branschen</h3>
                      <p className="mb-4">
                        Vi är dedikerade till att förse dig med kvalitetsbilar som är noggrant utvalda och kontrollerade.
                      </p>
                      <p className="mb-4">
                        Hos oss kan du sälja din bil enkelt och snabbt. Vi ser till att alla bilar vi köper är i utmärkt skick
                        och fungerar precis som de ska.
                      </p>
                      <p className="mb-4">
                        Vi samarbetar med MyMoney för att erbjuda räntefria lån upp till 100 000 kr, så att du kan finansiera
                        ditt köp på ett enkelt och prisvärt sätt.
                      </p>
                      <p>
                        Dessutom samarbetar vi med ICA Försäkring för att ge dig möjlighet att försäkra din bil och känna dig
                        trygg på vägarna.
                      </p>
                      <p>
                      Vi förstår värdet av din bil och vill se till att du får det bästa erbjudandet på marknaden. Därför erbjuder vi en stark prisgaranti. 
                      När du säljer eller förmedlar din bil genom oss kan du vara säker på att du får det högsta priset som är tillgängligt. 
                      Vår prisgaranti är vårt löfte till dig, och vi är fast beslutna att se till att du får rätt värde för din bil. 
                      Oavsett märke, modell eller skick kan du lita på att vi erbjuder dig det bästa priset. 
                      Vi värderar din tillit och är dedikerade till att ge dig en smidig och lönsam affär. Så när du väljer oss, väljer du också trygghet och den bästa affären på marknaden.
                      </p>
                    </Col>
                    <Col lg={6} xs={12}>
                      {/* Here, you can add a map with your location */}
                    </Col>
                  </Row>
                </Container>
              </section>
            </div>
          );
    }
export default AboutUs;