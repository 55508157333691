import './App.css';
import React, { useEffect, useState } from 'react';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import  Navigation  from './components/Navigation'
import { Footer } from './components/Footer';
import Contact from './components/Contact';
import BuyCarOuterMenu from './components/BuyCarOuterMenu';
import BuyCar from './components/BuyCar';
import AdminLogin from './components/AdminLogin';
import AdminHomePage from './components/AdminHomePage';
import AdminAddCar from './components/AdminAddCar';
import AdminEditCar from './components/AdminEditCar';
import Sellcar from './components/Sellcar';
import Faq from './components/Faq';
import { MantineProvider } from '@mantine/core';
import Verkstad from './components/Verkstad';

//Sälj bil
//Köp bil
//Support

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the user is authenticated using the token in localStorage or other means
    const token = localStorage.getItem('token');
    console.log("token in app.js " + token)

    if (token) {
      // Perform token validation here and set the authenticated state
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, []);


  return (
    <MantineProvider>
    <BrowserRouter>
      <div>
        <Navigation />
      
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/omoss' element={<AboutUs />} />
          <Route path='/hem' element={<Home />} />
          <Route path='/verkstad' element={<Verkstad />} />
          <Route path='/saljabil' element={<Sellcar />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/kopabil' element={<BuyCarOuterMenu />} />
          {/* <Route path="/AdminhomePage" element = {<ProtectedRoute> <AdminHomePage/></ProtectedRoute>}></Route> */}
          <Route
            path="/AdminHomePage"
            element={
              authenticated ?
                <AdminHomePage />
                : (
                  <Home />
                )
            }
          />
          <Route path='/buycar/:id' element={<BuyCar />} />
          <Route path='/adminLoginSH/' element={<AdminLogin />} />
          <Route path='/Faq/' element={<Faq />} />


          <Route
            path="/Addcar"
            element={
              authenticated ?
                <AdminAddCar />
                : (
                  <Home />
                )
            }
          />

          <Route
            path="/AdminEditCar/:idcar"
            element={
              authenticated ?
                <AdminEditCar />
                : (
                  <Home />
                )
            }
          />

        </Routes>
        <Footer />


      </div>
    </BrowserRouter>
    </MantineProvider>
  );
}

export default App;
