import React, {useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Nav.css'; // Import your custom CSS
import logoimage from '../Images/logo1.png';




  function Navigation() {
    const [expanded, setExpanded] = useState(false);
  
  
 
    
    return (
      <>
      <Navbar style={{ backgroundColor: '#11111F' }} expand="lg" className="justify-content-between navbarfollowingdown"  expanded={expanded}>

        {/* Toggle button (visible on mobile) */}
       <Container className="d-lg-none">
       

       <Navbar.Toggle
  aria-controls="basic-navbar-nav"
  style={{ backgroundColor: '#11111F' }}
  onClick={() => setExpanded(!expanded)}
>
  {expanded ? (
    <div className='bg-white' style={{"marginRight":"7px" }}>
      <span style={{ color: 'white', fontSize: '30px', width: '25px', backgroundColor: '#11111F', transition:"all 0.3s ease" }}>X</span>
    </div>
  ) : (
    <div>
      <span style={{ display: 'block', height: '2px', width: '25px', marginBottom: '6px', background: 'white' , transition:"all 0.3s ease" }}></span>
      <span style={{ display: 'block', height: '2px', width: '25px', marginBottom: '6px', background: 'white' , transition:"all 0.3s ease"}}></span>
      <span style={{ display: 'block', height: '2px', width: '25px', background: 'white' ,  transition:"all 0.3s ease"}}></span>
    </div>
  )}
</Navbar.Toggle>


        
        

        
      
        {/* Logo (visible on mobile) */}
          <Navbar.Brand className="d-lg-none" href="/">
            <img src={logoimage} alt="" height="70"/>
          </Navbar.Brand>
          <div></div>
          
          </Container>

        <Container>
          {/* Logo (Hidden on mobile) */}
          <Navbar.Brand className="d-none d-lg-block navclassmargin" href="/">
          <img src={logoimage} alt="a" height="70"/>
          </Navbar.Brand>
        </Container>

        

        {/* Navbar items (visible on mobile) */}
        <Container className='navclassmargin2'>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <NavLink className="nav-link d-inline text-white" to="/kopabil" onClick={() => setExpanded(false)}>
                Köpa Bil
              </NavLink>
              <NavLink className="nav-link d-inline text-white " to="/saljabil" onClick={() => setExpanded(false)}>
                Sälja Bil
              </NavLink>
              <NavLink className="nav-link d-inline text-white " to="/verkstad" onClick={() => setExpanded(false)}>
                Verkstad
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>

        <Container className='navclassmargin2'>
          <Navbar.Collapse>
            <Nav>
              <NavLink className="nav-link d-inline text-white" to="/Hem" onClick={() => setExpanded(false)}>
                Hem
              </NavLink>
              <NavLink className="nav-link d-inline text-white" to="/Contact" onClick={() => setExpanded(false)}>
                Kontakt
              </NavLink>
              <NavLink className="nav-link d-inline text-white" to="/omoss" onClick={() => setExpanded(false)}>
                Om oss
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className='divundernav'>
        <p>Vi står till förfogande sju dagar i veckan för att hjälpa dig köpa, byta eller sälja din bil ! </p>
      </div>
      </>
    );
  }

  export default Navigation;

