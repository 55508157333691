import React, { useEffect, useState } from 'react'
import './Admin.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';



const AdminHomePage = () => {

    const [data, setData] = useState([]);
    const navigate = useNavigate();
    
    const [selectedcar1, setSelectedcar1] = useState("");
    const [selectedcar2, setSelectedcar2] = useState("");
    const [selectedcar3, setSelectedcar3] = useState("");




    useEffect(() => {
        axios.get('https://auto-xchange-backend-h1sm.vercel.app/').then((response) => {
            setData(response.data)
            console.log(response.data);
            const carWithImage9 = response.data.find(car => car.image9 !== null);
            if (carWithImage9) {
                setSelectedcar1(carWithImage9.image9);
            }

            const carWithImage8 = response.data.find(car => car.image8 !== null);
            if (carWithImage8) {
                setSelectedcar2(carWithImage8.image8);
            }


            const carWithImage10 = response.data.find(car => car.image10 !== null);
            if (carWithImage10) {
                setSelectedcar3(carWithImage10.image10);
            }

            

        }).catch(error => {
            console.log(error);
        })
    }, [])

    const deleteQues = (e, id) => {
        e.preventDefault();
        axios.delete(`https://auto-xchange-backend-h1sm.vercel.app/deletecar/${id}`).then((response) => {
            console.log(response.data)
            console.log(id + " detta är id")
            //navigate('/AdminHomePage')

            updatee();




        }).catch(error => {
            console.log(error);
        })


    }

    const handleSignOut = () => {
        localStorage.removeItem('token');
        navigate("/adminloginsh")
    };

    const updatee = () => {
        axios.get('https://auto-xchange-backend-h1sm.vercel.app/').then((response) => {
            setData(response.data)
            console.log(response.data);
            console.log("Rad 45");
        }).catch(error => {
            console.log(error);
        })
    }
    /*
        const editQues = (e, id) => {
            e.preventDefault();
            navigate("/edit-question/"+id, {state:id})
            // <Link
            // to={{pathname:'/edit-question/'+id,
            //     state:id}}/>
            
        }
    */
    const addcarfunc = (e) => {
        e.preventDefault();


        navigate("/addcar")


    }

    const editcar = (e, idcar) => {
        e.preventDefault();


        navigate(`/AdminEditCar/${idcar}`);


    }

    const styles = {

        footer: {
            minHeight: "76vh",
            paddingBottom: "10px",

        }
    }

    const updateImage9 = () => {
        const updatedData = { selectedcar1: selectedcar1};
        axios
            .post(`https://auto-xchange-backend-h1sm.vercel.app/updatecarhomepage`, updatedData)
            .then((response) => {
                console.log(response.data);
                updatee();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateImage8 = () => {
        const updatedData = {selectedcar2: selectedcar2};
        axios
            .post(`https://auto-xchange-backend-h1sm.vercel.app/updatecarhomepage2`, updatedData)
            .then((response) => {
                console.log(response.data);
                updatee();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateImage10 = () => {
        const updatedData = {selectedcar3: selectedcar3};
        axios
            .post(`https://auto-xchange-backend-h1sm.vercel.app/updatecarhomepage3`, updatedData)
            .then((response) => {
                console.log(response.data);
                updatee();
            })
            .catch((error) => {
                console.log(error);
            });
    };



    return (


        <div className='container' style={styles.footer}>

            <h2 className='text-center mb-3'>Lista på bilar</h2>
            <div className='headerButtonown'>
                <button className='btn btn-primary' onClick={(e) => addcarfunc(e)}>Lägg till Bil</button>
                <button className='btn btn-danger' onClick={handleSignOut}>Logga ut</button>
            </div>
            {/* <Link to ="/add-question" className='btn btn-primary mb-2'> Lägg till Fråga </Link> */}
            <table className='table table-bordered table-striped'>
                <thead>

                    <th> Bilnamn</th>
                    <th> Bilmodell</th>
                    <th> Registeringsnummer</th>
                    <th> Årsmodell</th>
                    <th> Miltal</th>
                    <th> Växellåda</th>
                    <th> Bränsle</th>
                    <th> Såld?</th>
                    <th> Bild </th>
                    <th> Radera/Ändra </th>
                </thead>
                <tbody>
                    {
                        data
                        .sort((a, b) => (a.issold === 'Nej' ? -1 : 1))
                        .map(
                            data =>
                                <tr key={data.idcar}>
                                    <td>{data.carname}</td>
                                    <td>{data.subcarname}</td>
                                    <td>{data.registrationnumber}</td>
                                    <td>{data.yearmodel}</td>
                                    <td>{data.mileage}</td>
                                    <td>{data.gearbox}</td>
                                    <td>{data.fueltype}</td>
                                    <td>{data.issold}</td>
                                    <td>{data.image2}</td>
                                    <td><button className='btn btn-danger' onClick={(e) => deleteQues(e, data.idcar)}>Radera</button> <button className='btn btn-warning' onClick={(e) => editcar(e, data.idcar)}>Ändra</button></td>
                                </tr>
                        )
                    }
                </tbody>
            </table>

            {/* 3 choose car to be seen on homepage */}
            <div className='row'>
                <div className='col-lg-3'>
                    <p>Bil 1</p>
                    <select
                        name="fueltype"
                        className='form-control'
                        value={selectedcar1}
                        onChange={(e) => setSelectedcar1(e.target.value)}
                    >
                        {data.map((car) => (
                            <option key={car.idcar} value={car.registrationnumber}>
                                {car.registrationnumber}
                            </option>
                        ))}
                    </select>
                    <button
                    className='btn btn-primary'
                    onClick={() => updateImage9()}
                >
                    Update bil 1
                </button>

                </div>

                <div className='col-lg-3'>
                <p>Bil 2</p>
                    <select
                        name="fueltype"
                        className='form-control'
                        value={selectedcar2}
                        onChange={(e) => setSelectedcar2(e.target.value)}
                    >
                        {data.map((car) => (
                            <option key={car.idcar} value={car.registrationnumber}>
                                {car.registrationnumber}
                            </option>
                        ))}
                    </select>
                    <button
                    className='btn btn-primary'
                    onClick={() => updateImage8()}
                >
                    Update bil 2
                </button>

                </div>

                <div className='col-lg-3'>
                <p>Bil 3</p>
                    <select
                        name="fueltype"
                        className='form-control'
                        value={selectedcar3}
                        onChange={(e) => setSelectedcar3(e.target.value)}
                    >
                        {data.map((car) => (
                            <option key={car.idcar} value={car.registrationnumber}>
                                {car.registrationnumber}
                            </option>
                        ))}
                    </select>
                    <button
                    className='btn btn-primary'
                    onClick={() => updateImage10()}
                >
                    Update bil 3
                </button>

                </div>

                
            </div>
        </div>
    )
}

export default AdminHomePage