import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Car.css';
import './carosel.css';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faGears, faRoad, faGasPump, faPhone, faCircleNotch, faExpand } from '@fortawesome/free-solid-svg-icons'

function BuyCar() {
  const { id } = useParams();
  const [carDetails, setCarDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [customername, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [regNumber, SetRegnumber] = useState("");
  const [buyOrSell, setBuyOrSell] = useState("köpa");
  const [changeregNumber, SetChangeRegNumber] = useState("");
  const [changeCarMileage, SetChangeCarMileage] = useState("");
  const [phoneNumber, SetPhoneNumber] = useState("");
  const [city, SetCity] = useState("");
  const [message, setMessage] = useState("");

  //const [selectedImage, setSelectedImage] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [loadingforum, setLoadingforum] = useState(false);

  const [showFullscreen, setShowFullscreen] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState('');

  const handleThumbnailClick = (newImage, index) => {
    //setSelectedImage(newImage);
    setActiveIndex(index - 1);
  };

  const handleThumbnailClickfullScreen = (newImage) => {
    setFullscreenImage(newImage);
    setShowFullscreen(true);
  };

  const handleCloseFullscreen = () => {
    setShowFullscreen(false);
    setFullscreenImage('');
  };


  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const navigate = useNavigate();


  useEffect(() => {
    if (confirmationMessage) {
      setTimeout(() => {
        setConfirmationMessage('');
      }, 3000);
    }

    window.scrollTo(0, 0);
    axios.get(`https://auto-xchange-backend-h1sm.vercel.app/buycar/${id}`)
      .then((res) => {
        setCarDetails(res.data);
        //setSelectedImage(res.data.image2);
        SetRegnumber(carDetails.registrationnumber)
        document.title = "Bil Till Salu";
        setLoading(false);

      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div style={{ "alignItems": "center", "textAlign": "center", "marginBottom": "300px", "marginTop": "100px" }}>
        <h2 style={{ "color": "#774d04", "fontStyle": "italic" }}> Ett Ögonblick</h2>
        <FontAwesomeIcon icon={faCircleNotch} spin size="10x" style={{ "color": "df8f06" }} />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  function groupItems(items, groupSize) {
    const groupedItems = [];
    for (let i = 0; i < items.length; i += groupSize) {
      groupedItems.push(items.slice(i, i + groupSize));
    }
    return groupedItems;
  }

  // Usage in your component:
  const carOptions = carDetails.caroptions ? carDetails.caroptions.split(',') : [];
  const groupedOptions = groupItems(carOptions, 3);

  const imageFilenames = carDetails.image2.split(',').map(filename => filename.trim());


  const askquestion = async () => {

    try {
      const response = await axios.post('https://auto-xchange-backend-h1sm.vercel.app/buyorchange', {
        customername,
        email,
        regNumber,
        buyOrSell,
        changeregNumber,
        changeCarMileage,
        phoneNumber,
        city,
        message,
      });
      //console.log(response.data);
      setConfirmationMessage('Förfrågan har skickats! Vi hör av oss snart');
      return response.data;
    }
    catch (error) {
      console.error('Email could not be sent:', error);
      // Handle the error, e.g., display an error message to the user
      throw error; // Throw the error to be handled by the caller if needed
    }
  };

  const askquestionsendofrm = async (e) => {
    e.preventDefault();
    setLoadingforum(true); // Set loading to true when the form is submitted
    try {
      await askquestion();
      setCustomerName("");
      setEmail("");
      SetRegnumber("");
      SetCity("");
      SetPhoneNumber("");
      setBuyOrSell("köpa");
      setMessage("");
      SetChangeCarMileage("");
      SetChangeRegNumber("");
    } catch (error) {
      console.error('Email could not be sent:', error);
      // Handle error if needed
    } finally {
      setLoadingforum(false); // Set loading back to false after the request is completed
    }
  };
  const handleEmailOptionChange = (e) => {
    setBuyOrSell(e.target.value);
  };

  const gotoallcar = () => {
    navigate('/kopabil')
  }


  return (
    <div style={{ "overflow": "hidden" }}>
      <div className="car-page_margin">
        <div className="row" >
          <div className="col-lg-7 pos-rel" >
            <Carousel interval={null} activeIndex={activeIndex} onSelect={handleSelect} >


            {carDetails.imageUrls.map((url, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={url}
                    alt={``}
                   // onClick={() => handleThumbnailClick(url, index)}
                  />
                    <div className='enlargebutton'>  <FontAwesomeIcon className='btn enlargeicon' icon={faExpand} style={{ "color": "white", fontSize:"30px"}} onClick={() => handleThumbnailClickfullScreen(url)} size='3x'/></div>
                </Carousel.Item>
              ))}

            </Carousel>
            <div className='thumbnail-main-container row'>

              <div className="thumbnail-container   " style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap", WebkitOverflowScrolling: "touch" }}>

              {carDetails.imageUrls.map((url, index) => (
                  <img
                    key={index}
                    className="thumbnail-image"
                    src={url}
                    alt={`Thumbnail ${index + 1}`}
                    onClick={() => handleThumbnailClick(url, index + 1)}
                  />
                ))}

              </div>

            </div>
          </div>

          <Modal show={showFullscreen} onHide={handleCloseFullscreen} dialogClassName="modal-fullscreen">
            <Modal.Body className='modalbodytest'>
              <Button variant="secondary" onClick={handleCloseFullscreen}>
                Tillbaka
              </Button>
              <Carousel interval={null} activeIndex={activeIndex} onSelect={handleSelect} >


                {imageFilenames.map((filename, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100 imagetest10"
                      src={filename}
                      alt={''}
                      onClick={() => handleThumbnailClickfullScreen(filename)}
                    />
                  </Carousel.Item>
                ))}

              </Carousel>

            </Modal.Body>

          </Modal>





          <div className='col-lg-4 carpagerightcontent'>
            <div className='car-page__meta'>
              <div className='car-page__meta-section'>
                <h1 className='mb-0 text-uppercase car-page__meta-title'>{carDetails.carname}
                  <span className='car-page__meta-subtitle d-block'>{carDetails.subcarname}</span>
                </h1>
              </div>
              <hr />

              <div className='car-page__meta-section h-mb-2'>
                <p className='text-dark mb-0'>
                  <span className='text-dark'>Pris</span>
                  <br />
                  <span className='car-page__meta-price color-black'>{carDetails.price} kr</span>
                  <br />
                  <span class="d-block"></span>
                </p>
              </div>


              {/* Smårta lånet */}
              {/* <div>
                <div className='car-page__meta-choice h-mb-2'>
                  <p className='text-dark car-page__meta-choice-title text-uppercase fw-600 mb-0'>Smårta lånet</p>
                  <div className='price-detail float-right mt-0'>
                    <p className='car-page__meta-choice-price main-value text-dark mb-0'>396
                      <span className='main-value__suffix fw-300'> kr/mån</span>
                    </p>
                  </div>
                  <p className='car-page__meta-choice-meta text-dark mb-0'>Pris inkl. moms</p>
                  <p className='text-dark car-page__meta-choice-meta mb-0'>24 månader</p>
                </div>
              </div> */}

              <div className='car-page__description-section mb-4'>{carDetails.description}</div>


              {carDetails.issold === 'Nej' ? ( <a href="#kontaktforum" className='btn btn-success w-100 my-2'>Kontakta Säljaren</a>):(<p></p>)}
            </div>
          </div>
        </div>


      </div>

      <div className='carDetailsMain'>
        <div className='car-page_margin'>
          <h2 className='carDetailsMainHeader mb-4'><u>BILUPPGIFTER</u></h2>
          <div className='carDetailsMain-maininfo row'>

            <div className='carDetailsMain-itemOverview '>
              <FontAwesomeIcon className='cardetailsiconcode' icon={faCalendar} />
              <div>
                <p className='fw-bold mb-0'>ÅRSMODELL</p>
                <span>{carDetails.yearmodel}</span>
              </div>
            </div>

            <div className='carDetailsMain-itemOverview '>
              <FontAwesomeIcon className='cardetailsiconcode' icon={faGears} />
              <div>
                <p className='fw-bold mb-0'>VÄXELLÅDA</p>
                <span>{carDetails.gearbox}</span>
              </div>
            </div>

            <div className='carDetailsMain-itemOverview '>
              <FontAwesomeIcon className='cardetailsiconcode' icon={faRoad} />
              <div>
                <p className='fw-bold mb-0'>MILTAL</p>
                <span>{carDetails.mileage}</span>
              </div>
            </div>

            <div className='carDetailsMain-itemOverview '>
              <FontAwesomeIcon className='cardetailsiconcode' icon={faGasPump} />
              <div>
                <p className='fw-bold mb-0'>BRÄSNLE</p>
                <span>{carDetails.fueltype}</span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className='car-page_margin'>
        <h2 className='carDetailsMainHeader mb-4'><u>BILUTRUSTNING</u></h2>
        <div className='carEquipmentMenu'>
          {groupedOptions.map((group, groupIndex) => (
            <ul key={groupIndex}>
              {group.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          ))}
        </div>
      </div>


      {carDetails.issold === 'Nej' ? (
        <div className='carDetailsMain' id='kontaktforum'>

          <div className='row car-page_margin contactdivcode' >
            <div className='col-lg-4'>
              <h2 className='carDetailsMainHeader' ><u>Köp eller Byt Bil - Ditt Val, Vår Service</u></h2>
              <p>Fyll i blanketten och välj om du vill köpa bilen eller byta in din bil mot den här. Vår personal kommer att höra av direkt! För övrigt kan du alltid kontakta oss via mail eller telefon.</p>
              {/* <p style={{ "width": "fitcontent" }}><a href='tel:0762656575' style={{ "color": "black", "textDecoration": "unset" }}><FontAwesomeIcon style={{ "marginRight": "10px", "color": "#4ed11f" }} icon={faPhone} />076 265 6575</a></p> */}
              <p style={{ "width": "fitcontent" }}> <a href='tel:0708182583' style={{ "color": "black", "textDecoration": "unset" }}><FontAwesomeIcon style={{ "marginRight": "10px", "color": "#4ed11f" }} icon={faPhone} />070 818 2583</a></p>
            </div>

            <div className='col-lg-7'>
              <div className='kontaktaoss-main' >


                {loadingforum ? (
                  <div style={{ "alignItems": "center", "textAlign": "center", "marginTop": "20px" }}>
                    <h2 style={{ "color": "#774d04", "fontStyle": "italic" }}> Skickar Förfrågan...</h2>
                    <FontAwesomeIcon icon={faCircleNotch} spin size="10x" style={{ "color": "df8f06" }} />
                  </div>
                ) : (
                  <Form  method="post" className="form" onSubmit={askquestionsendofrm}>
                    <Row className='contactus-margin'>
                      <Col sm={6} className='phone-margin-formular'>
                        <Form.Group>

                          <Form.Control type="text" name="name" placeholder="Namn *" required className="form-control form-control-underlined color-text-base" value={customername} onChange={(e) => setCustomerName(e.target.value)} />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>

                          <Form.Control type="email" name="email" placeholder="E-postadress@domain.com *" value={email} onChange={(e) => setEmail(e.target.value)} required className="form-control form-control-underlined color-text-base" />
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* Phone number and city */}
                    <Row className='contactus-margin'>
                      <Col sm={6} className='phone-margin-formular'>
                        <Form.Group>

                          <Form.Control type="tel" name="phoneNumber" placeholder="Telefonnummer *" required className="form-control form-control-underlined color-text-base" value={phoneNumber} onChange={(e) => SetPhoneNumber(e.target.value)} />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>

                          <Form.Control type="text" name="city" placeholder="Din nuvarande stad tex Stockholm, Malmö" value={city} onChange={(e) => SetCity(e.target.value)} className="form-control form-control-underlined color-text-base" />
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* regnummer & köpa/byta */}
                    <Row className='contactus-margin'>
                      <Col sm={6} className='phone-margin-formular'>
                        <Form.Group>

                          <Form.Control type="text" name="name" placeholder="Regnr *" disabled required className="form-control form-control-underlined color-text-base" value={carDetails.registrationnumber} onChange={(e) => SetRegnumber(e.target.value)} />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Check
                            type="radio"
                            label="Jag vill köpa den här bilen"
                            name="buyOrchange"
                            value="köpa"
                            checked={buyOrSell === 'köpa'}
                            onChange={handleEmailOptionChange}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check
                            type="radio"
                            label="Jag vill byta in min nuvarande bil för den här bilen"
                            name="buyOrchange"
                            value="byta"
                            checked={buyOrSell === 'byta'}
                            onChange={handleEmailOptionChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* If i choose change car */}
                    {buyOrSell === "byta" && (
                      <Row className='contactus-margin'>
                        <Col sm={6} className='phone-margin-formular'>
                          <Form.Group>

                            <Form.Control type="text" name="changeregNumber" placeholder="Registreringsnummer på din bil *" required className="form-control form-control-underlined color-text-base" value={changeregNumber} onChange={(e) => SetChangeRegNumber(e.target.value)} />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group>

                            <Form.Control type="text" name="changeCarMileage" placeholder="Hur mycket har din bil rullat (Miltal) *" value={changeCarMileage} onChange={(e) => SetChangeCarMileage(e.target.value)} required className="form-control form-control-underlined color-text-base" />
                          </Form.Group>
                        </Col>
                      </Row>)}


                    <Form.Group>

                      <Form.Control as="textarea" rows={4} name="message" placeholder="Vänligen skriv dina kommentarer eller frågor här" value={message} onChange={(e) => setMessage(e.target.value)} className="form-control form-control-underlined color-text-base" />
                    </Form.Group>

                    <Button variant="outline-success" className='mt-2 mb-2' type='submit'>
                      Lämna in
                    </Button>
                  </Form>
                )}
                <h2 style={{ "color": "green", "fontStyle": "italic", "marginBottom": "15px" }}>{confirmationMessage}</h2>
              </div>
            </div>
          </div>

        </div>
      ) : (
        <div className='carDetailsMain'>

          <div className='row car-page_margin contactdivcode'>

            <h2 className='carDetailsMainHeader'><u>Denna Bil Har Redan Blivit Såld!</u></h2>
            <p>Vi vill meddela att den bilen du visar intresse för redan har blivit såld. Vi har dock ett brett urval av andra tillgängliga bilar som kan vara av intresse för dig.
              Vänligen utforska vårt aktuella lager och tveka inte att kontakta oss om du är intresserad av att köpa eller byta in din nuvarande bil. Vi ser fram emot att kunna
              erbjuda dig bästa möjliga service och assistans.</p>


          </div>
          <div className='text-center'>
            <Button className='btn btn-warning mb-3' onClick={gotoallcar}>Se våra bilar</Button>
          </div></div>
      )}

    </div>
  );
}

export default BuyCar;
