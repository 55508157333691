import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Faq.css';
import Accordion from 'react-bootstrap/Accordion';

function Faq() {
   

   return(

    <div className='py-5' style={{ "backgroundColor": "#fff9f1" }}>
    <Accordion className='faqmain'>
        <h3 className='faqheader'>Frågor & Svar</h3>
        <h5 className='mb-4'>Här finner du svar på många vanligt förekommande frågor. Om du inte hittar det du söker, står vår kontaktformulär till ditt förfogande på vår kontaktsida.
             Alternativt kan du alltid ringa oss på telefon för personlig assistans. Vi ser fram emot att hjälpa dig.</h5>
        <div></div>
    <Accordion.Item eventKey="0" className='mb-3' >
      <Accordion.Header><span className='fw-bold' style={{ fontStyle: 'italic' }}>Hur går bilvärderingen till när jag vill värdera min bil hos er?</span></Accordion.Header>
      <Accordion.Body>
      Vår bilvärderingsprocess är enkel och transparent. När du vill värdera din bil hos oss, erbjuder vi två alternativ: antingen köper vi bilen direkt av dig 
      eller så förmedlar vi försäljningen åt dig. 
      Innan vi påbörjar någon process får du alltid reda på priset. Vi strävar efter att vara marknadsledande och erbjuder därför prissgaranti
       – du kan vara trygg med att vi ger det högsta priset på marknaden. 
       Vi värderar din bil med omsorg och professionalism för att säkerställa att du får det bästa erbjudandet.
      </Accordion.Body>
    </Accordion.Item>
    
    <Accordion.Item eventKey="1" className='mb-3'>
      <Accordion.Header><span className='fw-bold' style={{ fontStyle: 'italic' }}>Hur kan jag vara säker på att bilen jag köper från er är i gott skick?</span></Accordion.Header>
      <Accordion.Body>
      Vi utför en noggrann kontroll på varje bil vi säljer för att säkerställa att den lever upp till höga kvalitetsstandarder. 
      Vårt dedikerade team felsöker varje bil både invändigt och utvändigt och dokumenterar eventuella problem i vår verkstad. 
      När du köper en bil från oss kan du vara trygg i vetskapen att vi har granskat och åtgärdat alla potentiella fel. Vårt mål 
      är att leverera bilar som fungerar felfritt och ger dig en trygg körupplevelse.
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="2" className='mb-3'>
      <Accordion.Header ><span className='fw-bold' style={{ fontStyle: 'italic' }}>Hur går köpet av en bil till hos er?</span></Accordion.Header>
      <Accordion.Body>
      Vi värdesätter transparens och kundens trygghet. När du är intresserad av att köpa en bil från oss har du alltid rätt att provköra den och 
      ta den tid du behöver för att noggrant undersöka bilen på alla möjliga sätt. För att säkerställa en smidig upplevelse rekommenderar vi att 
      du kontaktar oss innan ditt besök. På så sätt kan vi säkerställa att det finns tillgänglig personal på plats för att bistå och svara på 
      eventuella frågor du kan ha under din undersökning av bilen. Vi strävar efter att göra köpprocessen så enkel och bekväm som möjligt för dig.
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="3" className='mb-3'>
      <Accordion.Header><span className='fw-bold' style={{ fontStyle: 'italic' }}>Vilka betalningsalternativ erbjuder ni?</span></Accordion.Header>
      <Accordion.Body>
      Vi erbjuder flexibla betalningsalternativ för att göra det bekvämt för dig. 
      Du har möjlighet att betala med Swish eller kontantbetalning vid köp av en bil från oss. På samma sätt, 
      när vi köper eller förmedlar din bil, ger vi dig möjlighet att välja mellan få betalning med Swish eller kontantbetalning. 
      Vi anpassar oss efter dina önskemål för att göra hela processen så smidig som möjligt för dig.
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="4" className='mb-3'>
      <Accordion.Header><span className='fw-bold' style={{ fontStyle: 'italic' }}>Hur är processen när jag lämnar in min bil på er verkstad?</span></Accordion.Header>
      <Accordion.Body>
      För att göra hela processen så smidig som möjligt rekommenderar vi alltid att du kontaktar oss innan du lämnar in din bil. 
      Vår personal kommer att ge dig all den information du behöver, inklusive när din bil förväntas vara klar och annan relevant information. 
      Vi strävar efter att hålla dig informerad och säkerställa att du har en klar bild av vad du kan förvänta dig under verkstadsbesöket.
      </Accordion.Body>
    </Accordion.Item>

  </Accordion>
  </div>
   );
}

export default Faq;