import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './Admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'


const carOptionsData = [
    { id: 1, name: 'Bluetooth' },
    { id: 2, name: 'Servicebok' },
    { id: 3, name: '2 Nycklar' },
    { id: 4, name: 'El-speglar Fram' },
    { id: 5, name: 'Besiktad' },
    { id: 6, name: 'Elektrisk Baklucka' },
    { id: 7, name: 'MSDäck' },
    { id: 8, name: 'Sommar & Vinterdäck' },
    { id: 9, name: 'Finansering' },
    { id: 10, name: 'Svensk Såld' },
    { id: 11, name: 'Komplett Servicebok' },
    { id: 12, name: 'Farthålalre' },
    { id: 13, name: 'Type C Uttag' },
    { id: 14, name: 'Aux' },
    { id: 15, name: 'USB Uttag' },
    { id: 16, name: '12V Uttag' },
    { id: 17, name: 'Touch Skärm' },
    { id: 19, name: 'Navigator' },
    { id: 20, name: 'Sätesvärme Fram' },
    { id: 21, name: 'Sätesvärme Bak' },
    { id: 22, name: 'Isofix Fästen' },
    { id: 23, name: 'Multifunktionsratt' },
    { id: 24, name: 'Start & Stop Funktion' },
    { id: 25, name: 'Elstol' },
    { id: 26, name: 'Elektrisk Handbroms' },
    { id: 27, name: 'Panorama Tak' },
    { id: 29, name: 'Taklucka' },
    { id: 30, name: 'Bakkamera' },
    { id: 31, name: 'Baksensorer' },
    { id: 32, name: 'Framsensorer' },
    { id: 33, name: 'Helskin Klädsel' },
    { id: 34, name: 'Sportstolar' },
    { id: 35, name: 'Autohold' },
    { id: 36, name: 'Regnsensorer' },
    { id: 37, name: 'AC' },
    { id: 38, name: 'Separat AC' },
    { id: 39, name: 'Uppvärmda Sidospeglar' },
    { id: 40, name: 'Infällbara Sidospeglar' },
    { id: 41, name: 'Döda Vinkel Varning' },
    { id: 42, name: 'Dragkrok' },
    { id: 43, name: 'Android Auto' },
    { id: 44, name: 'Apple Carplay' },
    { id: 45, name: 'Keyless Entry' },
    { id: 46, name: 'Keyless Start' },
    { id: 47, name: 'Tonade Rutor' },
    { id: 48, name: 'Xenon Strålkastare' },
    { id: 49, name: 'Helljus Led' },
    { id: 50, name: 'Bakljus Led' },
    { id: 51, name: 'Avblandand Bakspegel' },
    { id: 52, name: 'Premium Ljudsystem' },
    { id: 53, name: 'Körfältsassistans' },
    { id: 54, name: 'Reservhjul' },
    { id: 55, name: 'Miljö Klass Euro 5' },
    { id: 56, name: 'Miljö Klass Euro 6' },
    { id: 57, name: 'Barnlås' },
    // Lägg till fler alternativ här
];

function AdminEditCar() {
    const [selectedOptions, setSelectedOptions] = useState([]);

    //const { id } = useParams();
    const { idcar } = useParams();
    console.log(idcar)
    const [carData, setCarData] = useState({
        carName: '',
        yearmodel: '',
        mileage: '',
        description: '',
        price: '',
        gearbox: 'Automat',
        numberOfPlaces: '5',
        fueltype: 'Bensin',
        registrationNumber: '',
        subcarname: '',
        caroptions: [],
        file: [],
        issold: '',
    });


    useEffect(() => {
        axios.get(`https://auto-xchange-backend-h1sm.vercel.app/buycar/${idcar}`).then((response) => {
            const car = response.data;
            console.log(car.image2)
            setCarData({
                carName: car.carname,
                yearmodel: car.yearmodel,
                mileage: car.mileage,
                description: car.description,
                price: car.price,
                gearbox: car.gearbox,
                numberOfPlaces: car.numberofplaces.toString(),
                fueltype: car.fueltype,
                registrationNumber: car.registrationnumber,
                subcarname: car.subcarname,
                caroptions: car.caroptions.split(','),
                issold: car.issold,
                file: car.image2 ? car.image2.split(', ') : []
            });
            setSelectedOptions(car.caroptions.split(','));
        });
    }, [idcar]);
    console.log(carData.file)

    const [file, setFile] = useState(null);

    const handleSave = async () => {
        const formData = new FormData();
        if (file) {

            for (let i = 0; i < file.length; i++) {
                formData.append('image', file[i]);
            }
        }

        formData.append('carName', carData.carName);
        formData.append('yearmodel', carData.yearmodel);
        formData.append('mileage', carData.mileage);
        formData.append('description', carData.description);
        formData.append('price', carData.price);
        formData.append('gearbox', carData.gearbox);
        formData.append('numberOfPlaces', parseInt(carData.numberOfPlaces, 10));
        formData.append('fueltype', carData.fueltype);
        formData.append('registrationNumber', carData.registrationNumber);
        formData.append('subcarname', carData.subcarname);
        formData.append('caroptions', selectedOptions.join(','));
        formData.append('issold', carData.issold);
        formData.append('oldimage', carData.file.join(', '));

        try {
            const response = await axios.put(`https://auto-xchange-backend-h1sm.vercel.app/updateCar/${idcar}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Car and images uploaded successfully:', response.data);
        } catch (error) {
            console.error('Car and image upload failed:', error);
        }

        window.location.reload(false);

        /* axios.put(`http://localhost:8080/updateCar/${idcar}`, updatedCarData).then(() => {
            back();
        }); */
    };

    const navigate = useNavigate();

    const styles = {
        footer: {
            minHeight: '76vh',
            paddingBottom: '10px',
        },
    };

    const back = () => {
        navigate('/AdminHomePage');
    };

    /*   const handleCheckboxChange = (option) => {
          // Clone the current caroptions array
          const updatedOptions = [...carData.caroptions];
  
          // Check if the option is already in the array
          const optionIndex = updatedOptions.indexOf(option);
  
          if (optionIndex !== -1) {
              // If the option is already selected, remove it
              updatedOptions.splice(optionIndex, 1);
          } else {
              // If the option is not selected, add it
              updatedOptions.push(option);
          }
  
          // Update the carData state with the new options
          setCarData({ ...carData, caroptions: updatedOptions });
      }; */

    const handleCheckboxChange = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };
    //const imageFilenames = carData.file.split(',').map(filename => filename.trim());

    const handleRemoveImage = async (e, index, image) => {
        e.preventDefault();
        try {
            const updatedImages = [...carData.file];

            if (index >= 0 && index < updatedImages.length) {
                updatedImages.splice(index, 1);
                // Remove any trailing commas and spaces
                const updatedImagesWithoutCommas = updatedImages.map((item) => item.trim()).filter((item) => item !== "");
                setCarData({ ...carData, file: updatedImagesWithoutCommas });

                const imageName = image.split('/').pop();
                await axios.put(`https://auto-xchange-backend-h1sm.vercel.app/deleteimage/${imageName}`).then(() => {
                    console.log("bilden som raderades " + imageName)

                });



            }
        } catch (error) {
            console.error('Car and image upload failed:', error);
        }
    };

    const moveImageUp = (e, index) => {
        e.preventDefault();
        if (index > 0) {
            const updatedImages = [...carData.file];
            const temp = updatedImages[index];
            updatedImages[index] = updatedImages[index - 1];
            updatedImages[index - 1] = temp;
            setCarData({ ...carData, file: updatedImages });
        }
    };

    const moveImageDown = (e, index) => {
        e.preventDefault();
        if (index < carData.file.length - 1) {
            const updatedImages = [...carData.file];
            const temp = updatedImages[index];
            updatedImages[index] = updatedImages[index + 1];
            updatedImages[index + 1] = temp;
            setCarData({ ...carData, file: updatedImages });
        }
    };




    return (
        <div className="card-body" style={styles.footer}>
            <button className="btn btn-warning mb-2" onClick={back}>
                Tillbaka
            </button>
            <form>
                {/* Render your edit car form here */}
                {/* Use the state variables in the form fields */}
                <div className="row m-3">
                    <div className="form-group mb-2 col-lg-6">
                        <label className="form-label fw-bold">Bilnamn</label>
                        <input
                            type="text"
                            placeholder="Bilnamn"
                            name="carname"
                            className="form-control"
                            value={carData.carName}
                            onChange={(e) => setCarData({ ...carData, carName: e.target.value })}
                        />
                    </div>

                    <div className="form-group mb-2 col-lg-6">
                        <label className="form-label fw-bold">Bilmodell</label>
                        <input
                            type="text"
                            placeholder="Bilmodell"
                            name="Bilmodell"
                            className="form-control"
                            value={carData.subcarname}
                            onChange={(e) => setCarData({ ...carData, subcarname: e.target.value })}
                        />
                    </div>
                </div>

                <div className="row m-3">
                    <div className="form-group mb-2 col-lg-6">
                        <label className="form-label fw-bold">Miltal</label>
                        <input
                            type="text"
                            placeholder="Miltal"
                            name="mileage"
                            className="form-control"
                            value={carData.mileage}
                            onChange={(e) => setCarData({ ...carData, mileage: e.target.value })}
                        />
                    </div>

                    <div className="form-group mb-2 col-lg-6">
                        <label className="form-label fw-bold">Årsmodell</label>
                        <input
                            type="text"
                            placeholder="Årsmodell"
                            name="yearmodel"
                            className="form-control"
                            value={carData.yearmodel}
                            onChange={(e) => setCarData({ ...carData, yearmodel: e.target.value })}
                        />
                    </div>
                </div>



                <div className="row m-3">
                    <div className="form-group mb-2 col-lg-6">
                        <label className="form-label fw-bold">Pris</label>
                        <input
                            type="text"
                            placeholder="Pris"
                            name="price"
                            className="form-control"
                            value={carData.price}
                            onChange={(e) => setCarData({ ...carData, price: e.target.value })}
                        />
                    </div>
                    <div className="form-group mb-2 col-lg-6">
                        <label className="form-label fw-bold">Växellåda</label>
                        <div>
                            <label className="form-check-label mx-2">
                                <input
                                    type="radio"
                                    name="gearbox"
                                    value="Automat"
                                    checked={carData.gearbox === 'Automat'}
                                    onChange={() => setCarData({ ...carData, gearbox: 'Automat' })}
                                    className="form-check-input"
                                />
                                Automat
                            </label>
                            <label className="form-check-label">
                                <input
                                    type="radio"
                                    name="gearbox"
                                    value="Manuell"
                                    checked={carData.gearbox === 'Manuell'}
                                    onChange={() => setCarData({ ...carData, gearbox: 'Manuell' })}
                                    className="form-check-input"
                                />
                                Manuell
                            </label>
                        </div>
                    </div>
                </div>


                <div className="row m-3">
                    <div className="form-group mb-2 col-lg-6">
                        <label className="form-label fw-bold">RegNummer</label>
                        <input
                            type="text"
                            placeholder="RegNummer"
                            name="registrationNumber"
                            className="form-control"
                            value={carData.registrationNumber}
                            onChange={(e) => setCarData({ ...carData, registrationNumber: e.target.value })}
                        />
                    </div>

                    <div className="form-group mb-2 col-lg-6">
                        <label className="form-label fw-bold">Beskrivning</label>
                        <input
                            type="text"
                            placeholder="Beskrivning"
                            name="description"
                            className="form-control"
                            value={carData.description}
                            onChange={(e) => setCarData({ ...carData, description: e.target.value })}
                        />
                    </div>
                </div>



                <div className='row m-3'>
                    <div className='form-group mb-2 col-lg-6'>
                        <label className='form-label fw-bold'>Antal Sittplatser</label>
                        <select
                            name="numberOfPlaces"
                            className='form-control'
                            value={carData.numberOfPlaces}
                            onChange={(e) => setCarData({ ...carData, numberOfPlaces: e.target.value })}
                        >
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                        </select>
                    </div>

                    <div className='form-group mb-2 col-lg-6'>
                        <label className='form-label fw-bold'>Bränsle</label>
                        <select
                            name="fueltype"
                            className='form-control'
                            value={carData.fueltype}
                            onChange={(e) => setCarData({ ...carData, fueltype: e.target.value })}
                        >
                            <option value="Bensin">Bensin</option>
                            <option value="Diesel">Diesel</option>
                            <option value="Elektrisk">Elektrisk</option>
                        </select>
                    </div>
                </div>


                <div className="row m-3">


                    <div className="form-group mb-2 col-lg-6">
                        <label className="form-label mx-4 fw-bold">Bilutrustning</label>
                        <div className="checkbox-group">

                            {carOptionsData.map((option) => (
                                <label key={option.id} style={{ marginRight: '10px' }}>
                                    <input
                                        type="checkbox"
                                        name="caroptions"
                                        value={option.name}
                                        checked={selectedOptions.includes(option.name)}
                                        onChange={() => handleCheckboxChange(option.name)}
                                        style={{ marginRight: '2px' }}
                                    />
                                    {option.name}
                                </label>
                            ))}


                        </div>
                    </div>

                    <div className="form-group mb-2 col-lg-6">
                        <label className="form-label fw-bold">Är Bilen Såld?</label>
                        <div>
                            <label className="form-check-label mx-2">
                                <input
                                    type="radio"
                                    name="issold"
                                    value="Ja"
                                    checked={carData.issold === 'Ja'}
                                    onChange={() => setCarData({ ...carData, issold: 'Ja' })}
                                    className="form-check-input"
                                />
                                Ja
                            </label>
                            <label className="form-check-label">
                                <input
                                    type="radio"
                                    name="issold"
                                    value="Nej"
                                    checked={carData.issold === 'Nej'}
                                    onChange={() => setCarData({ ...carData, issold: 'Nej' })}
                                    className="form-check-input"
                                />
                                Nej
                            </label>
                        </div>
                    </div>
                </div>


                <div className="form-group m-3">
                    <label className="form-label">Ladda upp bilder</label>
                    <div>
                        <input
                            type="file"
                            multiple
                            onChange={(e) => {
                                /* const newFiles = Array.from(e.target.files); // Convert FileList to an array
                                const updatedFiles = [...carData.file, ...newFiles]; // Concatenate new files with the existing ones
                                setCarData({ ...carData, file: updatedFiles });*/
                                const files = e.target.files;
                                setFile(files);
                                console.log("file: " + file)
                            }}
                        />
                        <div className="row">
                            <div className='uploaded-images'>
                                {carData.file && carData.file.map((image, index) => (
                                    <div key={index} className="image-container">
                                        <img
                                            src={image}
                                            alt={`imag`}
                                            className="uploaded-image"

                                        />
                                        <button onClick={(e) => moveImageUp(e, index)} className="move-image-left">
                                            <FontAwesomeIcon icon={faArrowLeft} style={{ "color": "orange" }} />
                                        </button> <button onClick={(e) => moveImageDown(e, index)} className="move-image-right">
                                            <FontAwesomeIcon icon={faArrowRight} style={{ "color": "orange" }} />
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                handleRemoveImage(e, index, image)
                                                // Call your additional function here

                                            }}
                                            className="delete-button"
                                        >
                                            <FontAwesomeIcon icon={faXmark} style={{ "color": "#ff0000" }} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Button className="btn btn-success m-3" onClick={handleSave}>
                    Spara
                </Button>
            </form>
        </div>
    );
}

export default AdminEditCar;
